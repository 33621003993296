/**
 * Direttiva che fissa allo scroll verticale della pagina aggiunge un pulsante che permette di tornare al top della pagina
 */

export interface IBackToTopDirectiveScope extends ng.IScope {
    scrollToTop: Function;
}

angular.module('app').directive('backToTop', ($window, $timeout, $state) => {
return {
    restrict: 'AE',
    template: '<md-button aria-label="Back to top" class="md-fab md-mini bg-primary-1 backToTop"><i class="material-icons md-icon-old-browser">&#xE316;</i></md-button>',
    link: ($scope: IBackToTopDirectiveScope, element: JQuery, attrs: ng.IAttributes) => {
        // Verifica la posizione dell'elemento, nascondendolo mi trovo al top della pagina
        let toggleButton = () => {
            if ($(window).scrollTop() <= 0) {
                $(element).fadeOut();
            }
            else {
                $(element).fadeIn();
            }
        }

        // Al click del pulsante, torno in testa alla pagina
        element.bind('click', () => {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        });

        // Quando scrollo, il pulsante deve apparire. Se sono in testa alla pagina, invece, deve nascondersi
        $(window).scroll((event) => {
            toggleButton();
        });

        // Affinchè la visibilità del pulsante non venga compromessa dai css di angular material, faccio una verifica sull'elemento anche una volta che la pagina è stata caricata
        angular.element(document).ready(() => {
            toggleButton();
        });
    }
  };
});